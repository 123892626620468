<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.title"
        label="搜索内容"
        v-bind="layout"
        placeholder="请输入内容关键词"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.content_type"
        v-bind="layout"
        key-field="id"
        label="内容形式"
        inner-search
        placeholder="请选择内容形式"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
      <BjSelect
        v-model="search.group_id"
        v-bind="layout"
        key-field="id"
        label="内容分组"
        inner-search
        placeholder="请选择分组"
        value-field="id"
        label-field="name"
        show-all
        :options="groupData"
      />
      <BjSelect
        v-model="search.author_id"
        v-bind="layout"
        key-field="id"
        label="作者信息"
        inner-search
        placeholder="全部作者"
        value-field="id"
        label-field="real_name"
        show-all
        :options="userData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="发布状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="statusData"
      />
    </BjSearch>
    <div :class="$style.content">
      <div :class="$style.title">
        分发列表
        <bj-button type="primary" :class="$style.btn" @click="onAdd()">
          <i class="ri-send-plane-2-line" />
          新建分发
        </bj-button>
      </div>

      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #cover="item">
          <div :class="$style.cover">
            <img class="cover" :src="item.cover.cover" />
            {{ item.title }}
          </div>
        </template>
        <template #media="item">
          <img v-for="citem in item.media" :key="citem.media_id" :class="$style.media" :src="citem.avatar" />
        </template>
        <template #action="item">
          <!-- <bj-link type="primary" @click="onPreview(item)"> 详情 </bj-link> -->
          <a-popconfirm title="确定要删除吗？" @confirm="onDelete(item)">
            <bj-link type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { spreadManageService } from '@/service'

const service = new spreadManageService()

export default {
  name: 'Home',
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      page: 1,
      search: {
        title: null,
        status: 0,
        group_id: [],
        content_type: [],
        author_id: [],
      },
      loading: false,
      statusData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '待发布',
          id: 1,
        },
        {
          name: '发布中',
          id: 2,
        },
        {
          name: '部分发布成功',
          id: 3,
        },
        {
          name: '全部发布成功',
          id: 4,
        },
        {
          name: '全部发布失败',
          id: 5,
        },
      ],
      groupData: [],
      data: [],
      typeData: [
        {
          name: '图文',
          id: 'post',
        },
        {
          name: '视频',
          id: 'video',
        },
        {
          name: '音频',
          id: 'audio',
        },
        {
          name: '图册',
          id: 'image',
        },
      ],
      userData: [],
    }
  },
  computed: {
    columns() {
      return [
        { title: '内容名称', dataIndex: 'title' },
        { title: '内容分组', dataIndex: 'group_name' },
        { title: '作者信息', dataIndex: 'author' },
        { title: '分发平台', dataIndex: 'media' },
        { title: '分发状态', dataIndex: 'status' },
        { title: '添加时间', dataIndex: 'created_at' },
        { title: '管理', dataIndex: 'action' },
      ]
    },
  },
  created() {
    this.getTypeList()
    this.getUser()
  },
  methods: {
    async getTypeList() {
      const { data } = await service.contentTypeList()
      this.groupData = data
    },
    async getUser() {
      const { data } = await service.getAuthor()
      this.userData = data.record
    },
    async getList() {
      try {
        const { data } = await service.getDistributeList({
          page: this.page,
          pageSize: 10,
          ...this.search,
        })
        this.data = data.record
      } catch (e) {}
    },
    onSearch() {
      this.getList()
    },
    init() {
      //
    },
    onAdd() {
      this.$router.push({
        name: 'spreadDistributeAdd',
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  .content {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
    .title {
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;

      .btn {
        float: right;
      }
    }
  }
}

.media {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.cover {
  display: flex;
  align-items: center;

  img {
    width: 70px;
    height: 44px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
  }
}
</style>
